import Api from "../../../Api"

const END_POINT_CREATE_ITEM_PO_MAQUINARIA = 'user/production-order/machinery/create';
const END_POINT_UPDATE_ITEM_PO_MAQUINARIA = 'user/production-order/machinery/update';
const END_POINT_DELETE_ITEM_PO_MAQUINARIA = 'user/production-order/machinery/delete';

export default {
  CreateItemPOMaquinaria(data, auth){
    return Api.post(END_POINT_CREATE_ITEM_PO_MAQUINARIA, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  UpdateItemPOMaquinaria(data, auth){
    return Api.patch(END_POINT_UPDATE_ITEM_PO_MAQUINARIA, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  DeleteItemPOMaquinaria(id, auth){
    const options = {
      headers: {'Authorization': 'Bearer ' + auth.access_token},
      params: { id: id }
    }
    return Api.delete(END_POINT_DELETE_ITEM_PO_MAQUINARIA,options);
  }
}