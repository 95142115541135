import Api from "../../../Api"

const END_POINT_CREATE_ITEM_PO_SERVICEMATERIAPRIMA = 'user/production-order/materia-prima/create';
const END_POINT_UPDATE_ITEM_PO_SERVICEMATERIAPRIMA = 'user/production-order/materia-prima/update';
const END_POINT_DELETE_ITEM_PO_SERVICEMATERIAPRIMA = 'user/production-order/materia-prima/delete';

export default {
  CreateItemPOMateriaPrima(data, auth){
    return Api.post(END_POINT_CREATE_ITEM_PO_SERVICEMATERIAPRIMA, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  UpdateItemPOMateriaPrima(data, auth){
    return Api.patch(END_POINT_UPDATE_ITEM_PO_SERVICEMATERIAPRIMA, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  DeleteItemQuotFPMateriaPrima(id, auth){
    const options = {
      headers: {'Authorization': 'Bearer ' + auth.access_token},
      params: { id: id }
    }
    return Api.delete(END_POINT_DELETE_ITEM_PO_SERVICEMATERIAPRIMA,options);
  }
}