<template>
  <div>
    <!-- Hero -->
    <base-page-heading
      title="Orden de producción"
      subtitle="| Editar órden de producción [Planeación]"
      class="heading-block"
    />    

    <div>
      <base-block rounded title="">
        <div class="block-content font-size-sm">
          <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-row class="ml-4 mt-1">
              <b-col sm="8">
                <b-row>
                  <b-col sm="5">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          # de cotización: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="quoteInfo.id"
                          disabled
                          size="sm"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="5">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Nombre del cliente: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="quoteInfo.client.nombre_comercial"
                          disabled
                          size="sm"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3" class="mt-2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Órden de producción: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="quoteInfo.service_order.orden_de_produccion"
                          size="sm"
                          disabled
                        ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3" class="mt-2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Órden de compra: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="quoteInfo.service_order.orden_de_compra"
                          disabled
                          size="sm"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3" class="mt-2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Fecha recepción ventas: <span class="text-danger">*</span>
                      </template>
                        <b-form-datepicker
                          id="datepicker"
                          v-model="quoteInfo.service_order.fecha_recepcion"
                          placeholder="Fecha de recepción" 
                          class="mb-2"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          disabled
                          ></b-form-datepicker>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3" class="mt-2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Hora recepción ventas: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="quoteInfo.service_order.hora_recepcion"
                          size="sm"
                          disabled
                        ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3" class="mt-2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Fecha recepción: <span class="text-danger">*</span>
                      </template>
                        <b-form-datepicker
                          id="datepicker"
                          v-model="quoteInfo.production_order.fecha_recepcion"
                          placeholder="Fecha de recepción" 
                          class="mb-2"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          disabled
                          ></b-form-datepicker>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3" class="mt-2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Hora recepción: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="quoteInfo.production_order.hora_recepcion"
                          size="sm"
                          disabled
                        ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4" class="mt-2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Fecha de entrega: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="quoteInfo.service_order.fecha_entrega"
                          disabled
                          size="sm"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="5" class="mt-2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                        Salida almacén: <span class="text-danger">*</span>
                      </template>
                      <select class="form-control" v-model="quoteInfo.production_order.warehouse_id">
                        <option :value="data.id" v-for="(data) in almacenesList" :key="data.id">
                          {{ data.name }}
                        </option>
                      </select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="5" class="mt-2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                        Sucursal: <span class="text-danger">*</span>
                      </template>
                      <select class="form-control" v-model="quoteInfo.service_order.branch_id" disabled>
                        <option :value="data.id" v-for="(data) in sucursalesList" :key="data.id">
                          {{ data.name }}
                        </option>
                      </select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3" class="mt-2">
                    <b-form-group label-for="largo_solicitado">
                      <template #label>
                        Asiento: $ {{ numberFormat.format(quoteInfo.production_order.asiento) }}
                      </template>
                      <b-input-group size="sm" prepend="$">
                        <b-form-input
                          v-model="quoteInfo.production_order.asiento"
                          size="sm"
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col sm="4" class="rounded border p-4 pt-0">
                <FileSystem v-if="quote_id != ''" view="cotizacion" :idItemView="quote_id" :files="files"></FileSystem>
              </b-col>
            </b-row>
            <b-row class="mt-4">
            </b-row>
            <br />
            <div v-for="(item_producto_terminado, index_Q_P_T) in selected_list_items_producto_terminado" :key="item_producto_terminado.q_f_p_id" class="mt-2">
              <div class="border radius p-2">
                <b-row class="mt-2">
                  <b-col sm="2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                        # de partida:
                      </template>
                      <b-form-input
                        :value='index_Q_P_T+1'
                        disabled
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                        # de piezas: <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        v-b-tooltip.hover title="Escriba el número de piezas"
                        v-model='item_producto_terminado.no_piezas'
                        size="sm"
                        disabled
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                        # de parte: <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        v-b-tooltip.hover title="Escriba el número de parte"
                        v-model='item_producto_terminado.no_parte'
                        disabled
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col offset-sm="2" sm="3">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          SELECCIONE MATERIA PRIMA O SERVICIO 
                      </template>
                      <select :disabled="item_producto_terminado.no_piezas==''" v-model="select_option" @change="Option($event,index_Q_P_T)" class="form-control">
                        <!--<option value="1">SERVICIO</option>-->
                        <option value="2">MATERIA PRIMA</option>
                        <option value="3">MAQUINARIA</option>
                        <option value="4">OPERADOR</option>
                      </select>
                      <span  style="color:red;" v-if="item_producto_terminado.no_piezas==''">Escriba el # de piezas primero</span>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <!-- MODAL -->
                  <b-modal
                    id="modal-items"
                    size="xl"
                    body-class="p-0"
                    hide-footer
                    hide-header
                    >
                    <div class="block block-rounded block-themed block-transparent mb-0">
                      <div class="block-header bg-primary-dark">
                        <h3 class="block-title">
                          <i class="fa fa-user mr-1"></i> Lista
                        </h3>
                          <div class="block-options">
                            <i style="cursor:pointer;" class="fa fa-fw fa-times" @click="$bvModal.hide('modal-items')"></i>
                          </div>
                      </div>
                      <div class="block-content font-size-sm">
                        <b-row>
                          <b-col offset-sm="9" sm="2">
                            <b-form inline>
                              <b-form-group label-for="no_cliente">
                                <template>
                                  Buscar:
                                </template>
                                <b-form-input
                                  v-model="searchTerm"
                                  size="sm"
                                ></b-form-input>
                              </b-form-group>
                            </b-form>
                          </b-col>
                        </b-row>
                        <div class="table-responsive">
                          <template v-if="select_option==1">
                            <b-table  
                              @row-clicked="SelectItemsServicios"
                              :items="servicios"
                              :fields="fields_servicios"
                              :current-page="currentPage"
                              :per-page="perPage"
                              responsive
                              striped
                              hover>
                            </b-table>
                          </template>
                          <template v-else-if="select_option==2">
                          <b-table 
                              @row-clicked="SelectItemsMateriaP"
                              :items="materiapFilter"
                              :fields="fields_materiap"
                              :current-page="currentPage"
                              :per-page="perPage"
                              responsive
                              striped
                              hover>
                            </b-table>
                          </template>
                          <template v-else-if="select_option==3">
                            <b-table 
                              @row-clicked="SelectItemsMaquinaria"
                              :items="maquinariasListFilter"
                              :fields="fields_maquinaria_list"
                              :current-page="currentPage"
                              :per-page="perPage"
                              responsive
                              striped
                              hover>
                            </b-table>
                          </template>
                          <template v-else-if="select_option==4">
                            <b-table
                              @row-clicked="SelectItemsOperadores"
                              :items="operadoresListFilter"
                              :fields="fields_operador_list"
                              :current-page="currentPage"
                              :per-page="perPage"
                              responsive
                              striped
                              hover>
                            </b-table>
                          </template>
                        </div>
                      </div>
                      <b-row>
                        <b-col sm="7" md="2" class="my-1">
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            align="fill"
                            size="sm"
                            class="my-0"
                          ></b-pagination>
                        </b-col>
                      </b-row>
                      <div class="block-content block-content-full text-right border-top">
                        <b-button
                          variant="alt-primary"
                          class="mr-1"
                          @click="$bvModal.hide('modal-items')"
                        >Cerrar</b-button>
                      </div>
                    </div>
                  </b-modal>
                  <!-- FIN MODAL -->
                </b-row>

                <b-row>
                  <!-- === MATERIA PRIMA === -->
                  <div class="container">
                    <b-row class="mt-4">
                      <div class="container">
                        <div class="row">
                          <div class="col-5" id="linea">
                            <hr>
                          </div>
                          <div class="col-2" id="titulo">
                            <p>Materia Prima</p>
                          </div>
                          <div class="col-5" id="linea">
                            <hr>
                          </div>
                        </div>
                      </div>
                    </b-row>
                    <b-row>
                      <b-col sm="12">
                        <div class="accordion" role="tablist">
                          <b-card no-body class="mb-1" v-for="(data, index_Q_P_T_S_M_P) in item_producto_terminado.po_materia_prima[0]" :key="data.index_Q_P_T_S_M_P">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                              <b-button id="button_accordion" block v-b-toggle="'accordion-'+index_Q_P_T+'-'+index_Q_P_T_S_M_P" variant="info">{{data.materia_prima_codigo ? data.materia_prima_codigo : data.codigo}} - {{data.materia_prima_descripcion ? data.materia_prima_descripcion : data.descripcion}}</b-button>
                              <i class="fa fa-trash" @click="DeletedSelectMateriaP(data.q_f_p_s_m_p_id,index_Q_P_T_S_M_P,index_Q_P_T)" style="cursor: pointer;position: absolute;float: left;left: 90%;border-radius: 20px;color: white;margin-top: -26px;"></i>
                              <i class="fa fa-save" @click="data.q_f_p_s_m_p_id > 0 ? UpdateItemMateriaPrima(index_Q_P_T, index_Q_P_T_S_M_P, item_producto_terminado.q_f_p_id, data.q_f_p_s_m_p_id) : SaveItemMateriaPrima(index_Q_P_T, index_Q_P_T_S_M_P, item_producto_terminado.q_f_p_id)" style="cursor: pointer;position: absolute;float: left;left: 93%;border-radius: 20px;color: white;margin-top: -26px;"></i>
                            </b-card-header>
                            <b-collapse :id="'accordion-'+index_Q_P_T+'-'+index_Q_P_T_S_M_P" accordion="my-accordion" role="tabpanel">
                              <b-card-body>
                                <br><br>
                                <b-row class="ml-4 mt-12">
                                  <b-col sm="12">
                                    <b-row style="padding: 1%;width: 99%;border-width: 1px;border-style: solid;border-color: #d0d0d0;border-radius: 5px;"> 
                                      <b-col sm="12">
                                        <b-form-group>
                                          <template #label>
                                            ESPECIFICACIONES
                                          </template>
                                        </b-form-group>
                                      </b-col>
                                      <b-row class="ml-1 w-100">
                                        <b-col sm="2">
                                          <b-form-group>
                                            <template #label>
                                              Código
                                            </template>
                                            <b-form-input
                                              disabled
                                              size="sm"
                                              v-model="data.codigo"
                                            ></b-form-input>
                                          </b-form-group>
                                        </b-col> 
                                        <b-col sm="3">
                                          <b-form-group>
                                            <template #label>
                                              Descripción
                                            </template>
                                            <b-form-input
                                              disabled
                                              v-model="data.descripcion"
                                              size="sm"
                                            ></b-form-input>
                                          </b-form-group>
                                        </b-col> 
                                        <b-col sm="2">
                                          <b-form-group>
                                            <template #label>
                                              Largo Estándar {{data.largo}}
                                            </template>
                                            <b-form-input
                                              disabled
                                              v-model="data.largo"
                                              size="sm"
                                            ></b-form-input>
                                          </b-form-group>
                                        </b-col> 
                                        <b-col sm="2">
                                          <b-form-group>
                                            <template #label>
                                              Largo Estándar (mm)
                                            </template>
                                            <b-form-input
                                              disabled
                                              :value="data.largo*10"
                                              size="sm"
                                            ></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="2">
                                          <b-form-group>
                                            <template #label>
                                              Familia
                                            </template>
                                            <b-form-input
                                              disabled
                                              v-model="data.materia_prima_family_name"
                                              size="sm"
                                            ></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <b-row class="ml-1 mt-3 w-100">
                                        <b-col sm="2">
                                          <b-form-group>
                                            <template #label>
                                              Peso teórico
                                            </template>
                                            <b-form-input
                                              disabled
                                              v-model="data.peso_teorico"
                                              size="sm"
                                            ></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="2">
                                          <b-form-group>
                                            <template #label>
                                              Peso x mm<sup>2</sup>
                                            </template>
                                            <b-form-input
                                              disabled
                                              v-model="data.peso_mm2"
                                              size="sm"
                                            ></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <br><br>
                                      <b-col sm="12" class="mt-4" v-show="vercostos">
                                        <b-form-group>
                                          <template #label>
                                            COSTOS
                                          </template>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="1" v-show="vercostos">
                                        <b-form-group label-for="costo">
                                          <template #label>
                                            Costo
                                          </template>
                                          <b-form-input
                                            :value="data.costo ? data.costo : data.costo_ferrebaztan ? data.costo = data.costo_ferrebaztan : data.service_type.costo_ferrebaztan ? data.costo = data.service_type.costo_ferrebaztan : -1"
                                            v-model="data.costo"
                                            size="sm"
                                            :disabled="cotizarPorPV"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="1" v-show="vercostos">
                                        <b-form-group label-for="margen">
                                          <template #label>
                                            Margen
                                          </template>

                                          <b-form-input
                                            v-if="cotizarPorPV" 
                                            v-model="data.margen"
                                            :value="data.margen = Number.parseFloat((((data.pv - data.costo)*100)/data.costo)).toFixed(2)"
                                            size="sm"
                                            disabled
                                          ></b-form-input>

                                          <!--data.margen ? data.margen : data.margen_from_services ? data.margen = data.margen_from_services : data.service_type.margen ? data.margen = data.service_type.margen : data.margen =-->


                                          <b-form-input
                                            v-if="!cotizarPorPV"
                                            v-model="data.margen"
                                            :value="data.margen ? data.margen : data.margen_from_services ? data.margen = data.margen_from_services : data.service_type.margen ? data.margen = data.service_type.margen : data.margen = -1"
                                            size="sm"
                                          ></b-form-input>

                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="4" v-show="vercostos">
                                        <b-row>
                                          <b-col sm="1">
                                            <b-form-group>
                                              <template #label>
                                                &nbsp;
                                              </template>
                                              <b-input-group size="sm" prepend="">
                                                <input type="checkbox" @click="cotizarPorPV=!cotizarPorPV" :checked="cotizarPorPV" />
                                              </b-input-group>
                                            </b-form-group>
                                          </b-col>
                                          <b-col sm="6">
                                            <b-form-group label-for="pv">
                                              <template #label>
                                                Cotizar por PV Kilo
                                              </template>
                                              <b-input-group size="sm" prepend="$">
                                                <b-form-input
                                                  v-if="!cotizarPorPV"
                                                  :value="data.pv ? data.pv = ((((data.costo*data.margen)/100))+(parseInt(data.costo))) : data.pv = data.pv_from_services"
                                                  v-model="data.pv"
                                                  size="sm"
                                                  disabled
                                                ></b-form-input>
                                                <b-form-input
                                                    v-if="cotizarPorPV"
                                                    :value="data.pv ? data.pv : data.pv_from_services ? data.pv = data.pv_from_services : data.service_type.pv ? data.pv = data.service_type.pv : data.pv = -1"
                                                    v-model="data.pv"
                                                    size="sm"
                                                  ></b-form-input>
                                              </b-input-group>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                      </b-col>
                                    </b-row>
                                    <br><br>
                                    <b-row style="position:relative;"> 
                                      <b-col sm="8" >
                                        <b-row>
                                          <b-col sm="4" >
                                            <!-- seccion 1 -->
                                            <b-row>
                                              <b-col sm="12" >
                                                <b-form-group>
                                                  <template #label>
                                                    LAMINAS/PLACAS A COTIZAR
                                                  </template>
                                                </b-form-group>
                                              </b-col>
                                              <b-col sm="8" >
                                                <b-form-group label-for="largo_solicitado">
                                                  <template #label>
                                                    # Laminas/Placas
                                                  </template>
                                                  <b-form-input
                                                    v-model="data.no_laminas_placas"
                                                    v-b-tooltip.hover title="Escriba el número de laminas/placas"
                                                    size="sm"
                                                  ></b-form-input>
                                                </b-form-group>
                                              </b-col>
                                            </b-row>
                                          </b-col>
                                          <b-col sm="8" >
                                            <b-row>
                                              <!-- seccion 2 -->
                                              <b-col sm="12">
                                                <b-form-group>
                                                  <template #label>
                                                    MATERIAL POR CORTE A COTIZAR
                                                  </template>
                                                </b-form-group>
                                              </b-col>
                                              <b-row>
                                                <b-col sm="6">
                                                  <b-form-group label-for="largo_solicitado">
                                                    <template #label>
                                                        Largo Solicitado (mm)
                                                    </template>
                                                    <b-form-input
                                                      v-b-tooltip.hover title="Escriba el largo solicitado (mm)"
                                                      id="largo_solicitado"
                                                      v-model="data.largo_solicitado"
                                                      size="sm"
                                                    ></b-form-input>
                                                  <!--    Precio:
                                                      $ {{ ((data.largo_solicitado * data.ancho) * data.peso_mm2) * data.pv }}
                                                  --></b-form-group>
                                                </b-col>
                                                <b-col sm="6">
                                                  <b-form-group label-for="ancho_solicitado">
                                                    <template #label>
                                                        Ancho solicitado (mm)
                                                    </template>
                                                    <b-form-input
                                                      v-b-tooltip.hover title="Escriba el ancho solicitado (mm)"
                                                      id="largo_solicitado"
                                                      v-model="data.ancho_solicitado"
                                                      size="sm"
                                                    ></b-form-input>
                                                  </b-form-group>
                                                </b-col>    
                                              </b-row>
                                              <!-- fin seccion 2 -->
                                            </b-row>
                                          </b-col>
                                        </b-row>

                                        <b-col sm="12" class="mt-4">
                                          <b-form-group>
                                            <template #label class="text-danger">
                                                TOTAL KILOS
                                            </template>
                                          </b-form-group>
                                        </b-col>

                                        <b-row>
                                          <b-col sm="4">
                                            <b-form-group label-for="kilos_solicitados">
                                              <template #label>
                                                Kilos Totales
                                              </template>
                                              <b-form-input
                                                disabled
                                                :value="data.kilos_totales = data.no_laminas_placas * data.peso_teorico"
                                                size="sm"
                                              ></b-form-input>
                                            </b-form-group>
                                          </b-col>

                                            + 

                                          <b-col sm="4">
                                            <b-form-group label-for="kilos_solicitados">
                                              <template #label>
                                                Kilos Solicitados
                                              </template>
                                              <b-form-input
                                                disabled
                                                :value="data.kilos_solicitados = (data.largo_solicitado * data.ancho_solicitado) * data.peso_mm2"
                                                size="sm"
                                              ></b-form-input>
                                            </b-form-group>
                                          </b-col> 

                                            =

                                          <b-col sm="2" class="ml-4">
                                            <b-form-group label-for="total">
                                              <template #label>
                                                Total Kilos
                                              </template>
                                              <b-form-input
                                                :value="data.total_de_kilos = data.kilos_totales + data.kilos_solicitados"
                                                disabled
                                                size="sm"
                                              ></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>

                                        <b-row class="mt-4">
                                          <b-col sm="6">
                                            <b-form-group label-for="kilos_solicitados">
                                              <template #label>
                                                Comentarios
                                              </template>
                                              <b-form-textarea
                                                v-model="data.comentarios"
                                                size="sm"
                                              ></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                      </b-col>

                                      <div class="v-line" v-show="vercostos"></div>

                                      <b-col sm="4" v-show="vercostos">
                                        <b-col sm="12">
                                          <b-form-group>
                                            <template #label>
                                              COSTOS TOTALES
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                        <b-row class="ml-4 mt-1" v-show="vercostos">
                                          <b-col sm="5">
                                            <b-form-group label-for="total">
                                              <template #label>
                                                Total
                                              </template>
                                              $ {{ data.total_dinero = parseFloat((data.kilos_totales + data.kilos_solicitados) * data.pv).toFixed(2) }}
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                        <b-row class="ml-4 mt-1" v-show="vercostos">
                                          <b-col sm="5">
                                            <b-form-group label-for="total">
                                              <template #label>
                                                Total x Pza
                                              </template>
                                              $ {{ data.total_x_pieza = ( isFinite( ( data.kilos_totales + data.kilos_solicitados ) * data.pv / item_producto_terminado.no_piezas) ) ? parseFloat((data.kilos_totales + data.kilos_solicitados) * data.pv / item_producto_terminado.no_piezas).toFixed(2) : 0 }}
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                        <b-row class="ml-4 mt-1" v-show="vercostos">
                                          <b-col sm="5">
                                            <b-form-group label-for="total">
                                              <template #label>
                                                Descuento %
                                              </template>
                                              <select class="form-control" v-model="data.descuento">
                                                <option value="0.00" :selected="[ (data.descuento) ? 'selected':'' ]">0%</option>
                                                <option value="5.00">5%</option>
                                                <option value="10.00">10%</option>
                                                <option value="20.00">20%</option>
                                              </select>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                        <b-row class="ml-4 mt-4" v-show="vercostos">
                                          <b-col sm="8">
                                            <b-form-group label-for="total">
                                              <template #label>
                                                Total (con/sin Descuento)
                                              </template>
                                              $ {{  data.total_con_descuento == 0 ? data.total_con_descuento = data.total_dinero : data.total_con_descuento = (data.total_dinero - ((data.total_dinero * data.descuento)/100)).toFixed(2) }}
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                      </b-col>                                                           
                                    </b-row>
                                    <br><br>
                                    <br><br>
                                  </b-col>
                                </b-row>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <!-- === FIN DE MATERIA PRIMA === -->

                  <!-- === MAQUINARIA === -->
                  <div class="container">
                    <b-row class="mt-4">
                      <div class="container">
                        <div class="row">
                          <div class="col-5" id="linea"><hr></div>
                          <div class="col-2" id="titulo"><p>Maquinaria</p></div>
                          <div class="col-5" id="linea"><hr></div>
                        </div>
                      </div>
                    </b-row>
                    <b-row>
                      <b-col sm="12">
                        <div class="accordion" role="tablist">
                          <b-card no-body class="mb-1" v-for="(data, index_Q_P_T_M) in item_producto_terminado.po_maquinaria[0]" :key="data.q_f_p_m_id">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                              <b-button id="button_accordion" block v-b-toggle="'accordion-maquinaria-'+index_Q_P_T+'-'+index_Q_P_T_M" variant="info">{{data.nombre_maquina}}</b-button>
                              <i class="fa fa-trash" @click="DeleteSelectedMaquinaria(data.q_f_p_m_id, index_Q_P_T_M, index_Q_P_T)" style="cursor: pointer;position: absolute;float: left;left: 90%;border-radius: 20px;color: white;margin-top: -26px;"></i>
                              <i class="fa fa-save" @click="data.q_f_p_m_id > 0 ? UpdateItemMaquinaria(index_Q_P_T, index_Q_P_T_M, item_producto_terminado.q_f_p_id, data.q_f_p_m_id) : SaveItemMaquinaria(index_Q_P_T, index_Q_P_T_M, item_producto_terminado.q_f_p_id)" style="cursor: pointer;position: absolute;float: left;left: 93%;border-radius: 20px;color: white;margin-top: -26px;"></i>
                            </b-card-header>
                            <b-collapse :id="'accordion-maquinaria-'+index_Q_P_T+'-'+index_Q_P_T_M" accordion="my-accordion-servicios" role="tabpanel">
                              <b-card-body>
                                <br><br>
                                <b-row class="ml-4 mt-12">
                                  <b-col sm="12">
                                    <b-row style="padding: 1%;width: 99%;border-width: 1px;border-style: solid;border-color: #d0d0d0;border-radius: 5px;"> 
                                      <b-col sm="12">
                                        <b-form-group>
                                          <template #label>
                                            ESPECIFICACIONES
                                          </template>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="2">
                                        <b-form-group>
                                          <template #label>
                                            Número de máquina
                                          </template>
                                          <b-form-input
                                            disabled
                                            v-model="data.numero_maquina"                                                                                
                                            size="sm"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col> 
                                      <b-col sm="3">
                                        <b-form-group>
                                          <template #label>
                                            Descripción
                                          </template>
                                          <b-form-input
                                            disabled
                                            v-model="data.nombre_maquina"                                                                                
                                            size="sm"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col> 
                                      <br><br>
                                      <b-col sm="12" class="mt-4" v-show="vercostos">
                                        <b-form-group>
                                          <template #label>
                                            COSTOS
                                          </template>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="2" v-show="vercostos">
                                        <b-form-group label-for="kilos_solicitados">
                                          <template #label>
                                            Costo x hora
                                          </template>
                                          <b-form-input
                                            :value="data.costo ? data.costo : data.costo = data.costo_hora"
                                            :other="data.pv ? data.pv : data.pv = data.costo_hora"
                                            v-model="data.costo"
                                            size="sm"
                                            disabled
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="1" v-show="vercostos">
                                        <b-form-group label-for="kilos_solicitados">
                                          <template #label>
                                            Margen
                                          </template>

                                          <b-form-input
                                            v-if="cotizarPorPVServicios"
                                            :value="data.margen = Number.parseFloat((((data.pv - data.costo)*100)/data.costo)).toFixed(2)"
                                            v-model="data.margen"
                                            size="sm"
                                            :disabled="cotizarPorPVServicios"
                                          ></b-form-input>

                                          <b-form-input
                                            v-if="!cotizarPorPVServicios"
                                            v-model="data.margen"
                                            size="sm"
                                          ></b-form-input>

                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="2" v-show="vercostos">
                                        <b-form-group label-for="kilos_solicitados">
                                          <template #label>
                                            Cotizar por PV
                                          </template>
                                          <b-row>
                                            <b-col sm="1">
                                              <input type="checkbox" @click="cotizarPorPVServicios=!cotizarPorPVServicios" :checked="cotizarPorPVServicios" />
                                            </b-col>

                                            <b-col sm="9">
                                              <b-form-input
                                                v-if="!cotizarPorPVServicios"
                                                :value="data.pv = Number.parseFloat(((((data.costo*data.margen)/100))+(parseFloat(data.costo)))).toFixed(2)"
                                                v-model="data.pv"
                                                size="sm"
                                                disabled
                                              ></b-form-input>

                                              <b-form-input
                                                v-if="cotizarPorPVServicios"
                                                :value="data.pv ? data.pv : data.pv = data.costo_hora"
                                                v-model="data.pv"
                                                ref="el"
                                                size="sm"
                                              ></b-form-input>
                                            </b-col>
                                          </b-row>
                                        </b-form-group>
                                      </b-col>
                                    </b-row>
                                    <br><br>
                                    <b-row class="position-relative"> 
                                      <b-col sm="8" >
                                        <b-row>
                                          <b-col sm="8" >
                                            <!-- seccion 1 -->
                                            <b-row>

                                              <b-col sm="12" >
                                                <b-form-group>
                                                  <template #label>
                                                    DATOS A COTIZAR
                                                  </template>
                                                </b-form-group>
                                              </b-col>

                                              <b-col sm="4" >
                                                <b-form-group label-for="largo_solicitado">
                                                  <template #label>
                                                    Minutos a utilizar
                                                  </template>
                                                  <b-form-input
                                                    :value="data.minutos ? data.minutos : data.minutos = 0"
                                                    v-model="data.minutos"
                                                    v-b-tooltip.hover title="Escriba el número de minutos"
                                                    size="sm"
                                                  ></b-form-input>
                                                </b-form-group>
                                              </b-col>

                                              <b-col sm="7">
                                                <b-form-group label-for="kilos_solicitados">
                                                  <template #label>
                                                    Comentarios
                                                  </template>
                                                  <b-form-textarea
                                                    v-model="data.comentarios"
                                                    size="sm"
                                                  ></b-form-textarea>
                                                </b-form-group>
                                              </b-col>
                                        
                                            </b-row>
                                          </b-col>
                                        </b-row>
                                      </b-col>
                                      <div class="v-line" style="height:100%" v-show="vercostos"></div>
                                      <b-col sm="4" v-show="vercostos">
                                        <b-row>
                                          <b-col sm="12">
                                            <b-form-group>
                                              <template #label>
                                                COSTOS TOTALES
                                              </template>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                        <b-row class="ml-4 mt-1">
                                          <b-col sm="5">
                                            <b-form-group label-for="total">
                                              <template #label>
                                                Total
                                              </template>
                                              $ {{ data.total_dinero = parseFloat((data.minutos / 60)  * data.pv).toFixed(2) }}
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                        <b-row class="ml-4 mt-1">
                                          <b-col sm="5">
                                            <b-form-group label-for="total">
                                              <template #label>
                                                Total x Pza
                                              </template>
                                              $ {{ data.total_x_pieza = parseFloat(data.total_dinero / item_producto_terminado.no_piezas).toFixed(2) }}
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                        <b-row class="ml-4 mt-1">
                                          <b-col sm="5">
                                            <b-form-group label-for="total">
                                              <template #label>
                                                  Descuento % 
                                              </template>
                                              <select class="form-control" v-model="data.descuento">
                                                <option value="0.00">0%</option>
                                                <option value="5.00">5%</option>
                                                <option value="10.00">10%</option>
                                                <option value="20.00">20%</option>
                                              </select>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                        <b-row class="ml-4 mt-4">
                                          <b-col sm="8">
                                            <b-form-group label-for="total">
                                              <template #label>
                                                Total (con/sin Descuento)
                                              </template>
                                              $ {{  data.total_con_descuento == 0 ? data.total_con_descuento = data.total_dinero : data.total_con_descuento = (data.total_dinero - ((data.total_dinero * data.descuento)/100)).toFixed(2) }}
                                            </b-form-group>
                                          </b-col>  
                                        </b-row>  
                                      </b-col>
                                    </b-row>
                                    <br><br>
                                    <br><br>
                                  </b-col>
                                </b-row>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <!-- ===  FIN DE MAQUINARIA === -->

                  <!-- === OPERADORES === -->
                  <div class="container">
                    <b-row class="mt-4">
                      <div class="container">
                        <div class="row">
                          <div class="col-5" id="linea">
                            <hr>
                          </div>
                          <div class="col-2" id="titulo">
                            <p>Operadores</p>
                          </div>
                          <div class="col-5" id="linea">
                            <hr>
                          </div>
                        </div>
                      </div>
                    </b-row>
                    <b-row>
                      <b-col sm="12">
                        <div class="accordion" role="tablist">
                          <b-card no-body class="mb-1" v-for="(data, index_Q_P_T_O) in item_producto_terminado.po_operadores[0]" :key="data.q_f_p_o_id">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                              <b-button id="button_accordion" block v-b-toggle="'accordion-operadores-'+index_Q_P_T+'-'+index_Q_P_T_O" variant="info">Operador: {{data.nombre_completo}}</b-button>
                              <i class="fa fa-trash" @click="DeleteSelectedOperador(data.q_f_p_o_id, index_Q_P_T_O, index_Q_P_T)" style="cursor: pointer;position: absolute;float: left;left: 90%;border-radius: 20px;color: white;margin-top: -26px;"></i>
                              <i class="fa fa-save" @click="data.q_f_p_o_id > 0 ? UpdateItemOperador(index_Q_P_T, index_Q_P_T_O, item_producto_terminado.q_f_p_id, data.q_f_p_o_id) : SaveItemOperador(index_Q_P_T, index_Q_P_T_O, item_producto_terminado.q_f_p_id)" style="cursor: pointer;position: absolute;float: left;left: 93%;border-radius: 20px;color: white;margin-top: -26px;"></i>
                            </b-card-header>
                            <b-collapse :id="'accordion-operadores-'+index_Q_P_T+'-'+index_Q_P_T_O" accordion="my-accordion-operadores" role="tabpanel">
                              <b-card-body>
                                <br><br>
                                <b-row class="ml-4 mt-12">
                                  <b-col sm="12">
                                    <b-row style="padding: 1%;width: 99%;border-width: 1px;border-style: solid;border-color: #d0d0d0;border-radius: 5px;"> 
                                      <b-col sm="12">
                                        <b-form-group>
                                            <template #label>
                                                ESPECIFICACIONES
                                            </template>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="2">
                                        <b-form-group>
                                            <template #label>
                                                Número de empleado
                                            </template>
                                            <b-form-input
                                                disabled
                                                v-model="data.no_empleado" 
                                                size="sm"
                                            ></b-form-input>
                                        </b-form-group>
                                      </b-col> 
                                      <b-col sm="4">
                                        <b-form-group>
                                          <template #label>
                                            Nombre del operador
                                          </template>
                                          <b-form-input
                                              disabled
                                              v-model="data.nombre_completo"
                                              size="sm"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col> 
                                      <br><br>
                                      <b-col sm="12" class="mt-4" v-show="vercostos">
                                        <b-form-group>
                                          <template #label>
                                            COSTOS
                                          </template>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="2" v-show="vercostos">
                                        <b-form-group label-for="kilos_solicitados">
                                          <template #label>
                                              Salario por hora
                                          </template>
                                          <b-form-input
                                              :value="data.costo ? data.costo : data.costo = data.salario_hora"
                                              :other="data.pv ? data.pv : data.pv = data.salario_hora"
                                              v-model="data.costo"
                                              size="sm"
                                              disabled
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="1" v-show="vercostos">
                                        <b-form-group label-for="kilos_solicitados">
                                          <template #label>
                                            Margen
                                          </template>

                                          <b-form-input
                                            v-if="cotizarPorPVOperadores"
                                            :value="data.margen = Number.parseFloat((((data.pv - data.costo)*100)/data.costo)).toFixed(2)"
                                            v-model="data.margen"
                                            size="sm"
                                            :disabled="cotizarPorPVOperadores"
                                          ></b-form-input>

                                          <b-form-input
                                            v-if="!cotizarPorPVOperadores"
                                            v-model="data.margen"
                                            size="sm"
                                            :disabled="cotizarPorPVOperadores"
                                          ></b-form-input>

                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="2" v-show="vercostos">
                                        <b-form-group label-for="kilos_solicitados">
                                          <template #label>
                                            Cotizar por PV
                                          </template>
                                          <b-row>
                                            <b-col sm="1">
                                              <input type="checkbox" @click="cotizarPorPVOperadores=!cotizarPorPVOperadores" :checked="cotizarPorPVOperadores" />
                                            </b-col>

                                            <b-col sm="9">
                                              <b-form-input
                                                v-if="!cotizarPorPVOperadores"
                                                :value="data.pv = Number.parseFloat(((((data.costo*data.margen)/100))+(parseFloat(data.costo)))).toFixed(2)"
                                                v-model="data.pv"
                                                size="sm"
                                                disabled
                                              ></b-form-input>

                                              <b-form-input
                                                v-if="cotizarPorPVOperadores"
                                                :value="data.pv ? data.pv : data.salario_hora"
                                                v-model="data.pv"
                                                size="sm"
                                              ></b-form-input>

                                            </b-col>


                                          </b-row>
                                        </b-form-group>
                                      </b-col>
                                    </b-row>
                                    <br><br>
                                    <b-row class="position-relative"> 
                                      <b-col sm="8" >
                                        <b-row>
                                          <b-col sm="8" >
                                            <!-- seccion 1 -->
                                            <b-row>
                                              <b-col sm="12" >
                                                <b-form-group>
                                                  <template #label>
                                                    DATOS A COTIZAR
                                                  </template>
                                                </b-form-group>
                                              </b-col>
                                              <b-col sm="4" >
                                                <b-form-group label-for="largo_solicitado">
                                                  <template #label>
                                                      Minutos a utilizar
                                                  </template>
                                                  <b-form-input
                                                      v-model="data.minutos"
                                                      v-b-tooltip.hover title="Escriba el número de minutos"
                                                      size="sm"
                                                  ></b-form-input>
                                                </b-form-group>
                                              </b-col>
                                              <b-col sm="7">
                                                <b-form-group label-for="kilos_solicitados">
                                                  <template #label>
                                                    Comentarios
                                                  </template>
                                                  <b-form-textarea
                                                    v-model="data.comentarios"
                                                    size="sm"
                                                  ></b-form-textarea>
                                                </b-form-group>
                                              </b-col>
                                            </b-row>
                                          </b-col>
                                        </b-row>
                                      </b-col>
                                      <div class="v-line" style="height:100%" v-show="vercostos"></div>
                                      <b-col sm="4" v-show="vercostos">
                                        <b-col sm="12">
                                          <b-form-group>
                                            <template #label>
                                              COSTOS TOTALES
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                        <b-row class="ml-4 mt-1" v-show="vercostos">
                                          <b-col sm="5">
                                            <b-form-group label-for="total">
                                              <template #label>
                                                Total
                                              </template>
                                              $ {{ data.total_dinero = parseFloat((data.minutos/60) * data.pv).toFixed(2) }}
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                        <b-row class="ml-4 mt-1" v-show="vercostos">
                                          <b-col sm="5">
                                            <b-form-group label-for="total">
                                              <template #label>
                                                  Total x Pza
                                              </template>
                                              $ {{ data.total_x_pieza = parseFloat(data.total_dinero / item_producto_terminado.no_piezas).toFixed(2) }}
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                        <b-row class="ml-4 mt-1" v-show="vercostos">
                                          <b-col sm="5">
                                            <b-form-group label-for="total">
                                              <template #label>
                                                Descuento %
                                              </template>
                                              <select class="form-control" v-model="data.descuento">
                                                <option value="0.00" :selected="[ (data.descuento) ? 'selected':'' ]">0%</option>
                                                <option value="5.00">5%</option>
                                                <option value="10.00">10%</option>
                                                <option value="20.00">20%</option>
                                              </select>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>                                                                            
                                        <b-row class="ml-4 mt-4" v-show="vercostos">
                                          <b-col sm="8">
                                            <b-form-group label-for="total">
                                              <template #label>
                                                Total (con/sin Descuento)
                                              </template>
                                                $ {{  data.total_con_descuento == 0 ? data.total_con_descuento = data.total_dinero : data.total_con_descuento = (data.total_dinero - ((data.total_dinero * data.descuento)/100)).toFixed(2) }}
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                      </b-col>                                                           
                                    </b-row>
                                    <br><br>
                                    <br><br>
                                  </b-col>
                                </b-row>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <!-- === FIN DE OPERADORES === -->

                  <!-- === PRODUCTO TERMINADO === -->
                  <div class="container">
                    <b-row class="mt-4">
                      <div class="container">
                        <div class="row">
                          <div class="col-5" id="linea">
                            <hr>
                          </div>
                          <div class="col-2" id="titulo">
                            <p>Producto terminado</p>
                          </div>
                          <div class="col-5" id="linea">
                            <hr>
                          </div>
                        </div>
                      </div>
                    </b-row>
                    <b-row>
                      <b-col sm="12">
                        <div class="accordion" role="tablist">
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                              <b-button id="button_accordion" block v-b-toggle="'accordion-producto-terminado'+index_Q_P_T" variant="info">Producto Terminado: {{item_producto_terminado.descripcion}}</b-button>
                              <i class="fa fa-trash" @click="DeleteSelectedProductoTerminado(index_Q_P_T)" style="cursor: pointer;position: absolute;float: left;left: 90%;border-radius: 20px;color: white;margin-top: -26px;"></i>
                              <i class="fa fa-save" @click="SaveItemProductoTerminado(index_Q_P_T)" style="cursor: pointer;position: absolute;float: left;left: 93%;border-radius: 20px;color: white;margin-top: -26px;"></i>
                            </b-card-header>
                            <b-collapse :id="'accordion-producto-terminado'+index_Q_P_T" accordion="my-accordion-producto-terminado" role="tabpanel">
                              <b-card-body>
                                <br><br>
                                <b-row class="ml-4 mt-12">
                                  <b-col sm="12">
                                    <b-row style="padding: 1%;width: 99%;border-width: 1px;border-style: solid;border-color: #d0d0d0;border-radius: 5px;"> 
                                      <b-col sm="12">
                                        <b-form-group>
                                          <template #label>
                                            ESPECIFICACIONES
                                          </template>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="6">
                                        <b-form-group>
                                          <template #label>
                                            Código
                                          </template>
                                          <b-form-input
                                            disabled
                                            v-model="item_producto_terminado.codigo"
                                            size="sm"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col> 
                                      <b-col sm="6">
                                        <b-form-group>
                                          <template #label>
                                            Descripción
                                          </template>
                                          <b-form-input
                                            disabled
                                            v-model="item_producto_terminado.descripcion"
                                            size="sm"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="6">
                                        <b-form-group>
                                          <template #label>
                                            Comentarios
                                          </template>
                                          <b-form-input
                                            v-model="item_producto_terminado.comentarios"
                                            disabled
                                            size="sm"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="6">
                                        <b-form-group>
                                          <template #label>
                                            Cantidad
                                          </template>
                                          <b-form-input
                                            disabled
                                            v-model="item_producto_terminado.no_piezas"
                                            size="sm"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col>
                                      <br><br>
                                    </b-row>
                                    <br><br>
                                    <br><br>
                                  </b-col>
                                </b-row>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <!-- === FIN DE PRODUCTO TERMINADO -->

                </b-row>
              </div>

            </div>
            <br />
          </form>
        </div>
        <div  class="block-content block-content-full text-right border-top">
          <b-button variant="alt-primary"  class="mr-1"  @click="Cancelar()">Cancelar</b-button>
          <b-button variant="primary" @click="ImprimirEtiquetas()">Imprimir etiquetas</b-button>
          <b-button variant="primary" class="ml-1" @click="UpdateProductionOrder()">Guardar</b-button>
          <b-button variant="primary" class="ml-1" @click="quoteInfo.roadmap.id ? ViewRoadMap() : SaveAndCreateRoadMap()">Guardar y {{ quoteInfo.roadmap.id ? 'ver' : 'generar' }} Hoja Viajera</b-button>
        </div>
        </base-block>
    </div>
  </div>
</template>

<script>

  import Api from "../../../api/Api"
  import Clientes from "../../../api/admin/clientes/Clientes";
  import MateriaP from "../../../api/admin/materia_prima/Materia_Prima";
  import PreCotizacion from "../../../api/admin/cotizacion/Pre_cotizacion";
  import Sucursal from "../../../api/admin/ubicacion/Sucursales"
  import Almacen from "../../../api/admin/ubicacion/Almacenes"
  import OrdenProduccion from "../../../api/admin/orden_produccion/Orden_Produccion"
  import HojaViajera from "../../../api/admin/hoja_viajera/Hoja_Viajera"
  import Maquinarias from "../../../api/admin/maquinaria/Maquinarias";
  import Empleados from "../../../api/admin/empleados/Empleados";

  import MateriaPrima from "../../../api/admin/orden_produccion/materia_prima/MateriaPrima";
  import Maquinaria from "../../../api/admin/orden_produccion/maquinaria/Maquinaria";
  import Operador from "../../../api/admin/orden_produccion/operador/Operador";

  import FileSystem from "../../../components/FileSystem.vue"

  import Swal from "sweetalert2";

  export default {

    components: {
      FileSystem
    },

    data() {
      return {
        hostEndPointURL:Api.defaults.appBaseURL,
        quote_id:0,
        quoteInfo:{client:[],service_order:[],production_order:[]},
        productoTerminadoList:[],
        select_option:'',
        currentPage: 1,
        perPage: 5,
        itemQFPIndex:0,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        searchTerm:'',
        totalRows:0,
        currentQuote:0,
        sucursalesList:[],
        almacenesList:[],
        materiap:[],
        maquinariasList:[],
        operadoresList:[],
        cotizarPorPV:true,
        cotizarPorPVServicios:true,
        cotizarPorPVOperadores:true,
        vercostos:false,
        files:[],
        form: {
            cotizacion_id:'',
            client_id:'',
            orden_compra: '5DF8ER2',
            fecha_recepcion: '26-04-2022',
            hora_recepcion:'12:00',
            sucursal:'Sucursal 1',
            almacen:'Almacen 1',
            id_sucursal:null,
            id_almacen:null
        },
        fields_materiap: [
          {key: 'codigo',sortable: true, label: 'CÓDIGO MACRO'},
          {key: 'descripcion',sortable: true, label: 'DESCRIPCIÓN'},
          {key: 'materia_prima_family', formatter: value => {
              return value.name
          },sortable: true, label: 'FAMILIA'},
          {key: 'materia_prima_unit', formatter: value => {
              return value.name
          },sortable: true, label: 'UNIDAD'},
          {key: 'service_type', formatter: value => {
              return value.nombre.length ? value.nombre : 'No especificado'
          },sortable: true, label: 'SERVICIO'},
          {key: 'peso_teorico',sortable: true, label: 'PESO TEORICO'},
          {key: 'maximo',sortable: true, label: 'MÁXIMO'},
          {key: 'minimo',sortable: true, label: 'MÍNIMO'},
        ],
        fields_maquinaria_list: [
          {key: 'numero_maquina',sortable: true, label: 'NÚMERO DE MAQUINA'},
          {key: 'nombre_maquina',sortable: true, label: 'NOMBRE DE LA MÁQUINA'},
          {key: 'costo_hora',sortable: true, label: 'COSTO HORA'}
        ],
        fields_operador_list: [
          {key: 'no_empleado',sortable: true, label: 'NÚMERO DE OPERADOR'},
          {key: 'nombre_completo',sortable: true, label: 'NOMBRE COMPLETO'},
          {key: 'salario_diario',sortable: true, label: 'SALARIO DIARIO'},
          {key: 'salario_hora',sortable: true, label: 'SALARIO HORA'}
        ],
        fields_producto_terminado_list: [
          {key: 'codigo', sortable: true, label: 'CÓDIGO'},
          {key: 'descripcion', sortable: true, label: 'DESCRIPCIÓN'},
          {key: 'kgs_procesados', sortable: true, label: 'KGS PROCESADOS'},
          {key: 'finished_product_family', formatter: value => {
                return value.name.length ? value.name : 'No especificado'
            },sortable: true, label: 'FAMILIA'},
          {key: 'finished_product_unit', formatter: value => {
                return value.name.length ? value.name : 'No especificado'
            },sortable: true, label: 'UNIDAD'},
          {key: 'maximo',sortable: true, label: 'MÁXIMO'},
          {key: 'minimo',sortable: true, label: 'MÍNIMO'},
          {key: 'actions',sortable: true, label: 'ACCIONES'},
        ],
        select_list_items_materiap: [],
        selected_list_items_producto_terminado:[],
        optionsMoneyFormat:{ style: 'currency', currency: 'MXN' },
        numberFormat:new Intl.NumberFormat('es-MX', this.optionsMoneyFormat),
      }
    },

    computed:{
      materiapFilter(){
        return this.materiap.filter((item) => {
          if (this.searchTerm === "") {
            return item;
          } else if ( 
            item.codigo.toLowerCase().includes(this.searchTerm.toLowerCase()) || 
            item.descripcion.toLowerCase().includes(this.searchTerm.toLowerCase())
          ) {
            return item;
          }
        })
      },
      maquinariasListFilter(){
        return this.maquinariasList.filter((item) => {
          if (this.searchTerm === "") {
            return item;
          } else if ( 
            item.nombre_maquina.toLowerCase().includes(this.searchTerm.toLowerCase())
          ) {
            return item;
          }
        })
      },

      operadoresListFilter(){
        return this.operadoresList.filter((item) => {
          if (this.searchTerm === "") {
            return item;
          } else if ( 
            item.no_empleado.toString().includes(this.searchTerm.toLowerCase()) ||
            item.nombre_completo.toLowerCase().includes(this.searchTerm.toLowerCase())
          ) {
            return item;
          }
        })
      },
    },

    methods: {

      Cancelar(){
        this.$router.push({
          path: "/cotizacion/list"
        });
      },
      
      ImprimirEtiquetas(){
        const context = this
        window.open(`${context.hostEndPointURL}tags/pdf/${context.quote_id}`, '_blank');
      },

      GetQuoteInfo(id){
        const context = this;
        let auth = JSON.parse(localStorage.autentication);
        PreCotizacion.GetQuot(id,auth)
        .then((response) => {
          const responseQuoteInfo = response.data.data
          context.quoteInfo = responseQuoteInfo
          if(responseQuoteInfo.roadmap == null){
            context.quoteInfo.roadmap = []
          }
          context.selected_list_items_producto_terminado = responseQuoteInfo.finished_products
          context.files = responseQuoteInfo.files
          console.log(context.quoteInfo)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      AllClientes() {
        let auth = JSON.parse(localStorage.autentication);
        Clientes.GetAllCustomers(auth)
        .then((response) => {
          this.clientes = response.data;
        })
        .catch((error) => {
          console.log(error.data);
        });
      },

      AllSucursales(){
        let auth = JSON.parse(localStorage.autentication);
        Sucursal.GetAllSucursales(auth)
        .then((response) => {
          this.sucursalesList = response.data;
        })
        .catch((error) => {
          console.log(error.data);
        });
      },

      AllAlmacenes(){
        let auth = JSON.parse(localStorage.autentication);
        Almacen.GetAllAlmacenes(auth)
        .then((response) => {
          this.almacenesList = response.data;
        })
        .catch((error) => {
          console.log(error.data);
        });
      },

      SelectItemsMateriaP(data_select){
        let me = this

        if (me.selected_list_items_producto_terminado[me.itemQFPIndex].po_materia_prima == '') {
          console.log("arreglo vacío: "+me.itemQFPIndex);
          localStorage.lista_items_materiap = JSON.stringify(data_select);
          //let data = JSON.parse(localStorage.lista_items_materiap)
          const costo = data_select.service_type.costo_ferrebaztan
          const margen = data_select.service_type.margen
          const pv = data_select.service_type.pv

          data_select = {...data_select,
            costo:costo,
            margen:margen,
            pv:pv,
            no_laminas_placas:0,
            largo_solicitado:0,
            ancho_solicitado:0,
            comentarios:'',
            total_con_descuento:0,
            descuento:0
          }

          me.selected_list_items_producto_terminado[me.itemQFPIndex].po_materia_prima[0].push(data_select)

          me.$bvModal.hide("modal-items"); 
          me.select_option = '';

        } else {
          console.log("arreglo lleno checando: "+me.itemQFPIndex);
          me.selected_list_items_producto_terminado[me.itemQFPIndex].po_materia_prima.filter(function(element) {
            console.log("arreglo lleno: ");
            for(let i=0; i<element.length; i++ ){
              if ( element[i].id == data_select.id ){
                Swal.fire({
                  title: "Que mal!",
                  text: 'Ya está agregado a la lista',
                  icon: "error",
                });
                me.$bvModal.hide("modal-items");
                me.select_option = '';
                return;
              } 
            }

            data_select = {...data_select,
              costo:'',
              margen:'',
              pv:'',
              no_laminas_placas:0,
              largo_solicitado:0,
              ancho_solicitado:0,
              comentarios:'',
              total_con_descuento:0,
              descuento:0
            }
            console.log("no deberias estar haciendo el push")
            me.selected_list_items_producto_terminado[me.itemQFPIndex].po_materia_prima[0].push(data_select);
            me.$bvModal.hide("modal-items");
            me.select_option = '';
          });
        }
      },

      SelectItemsMaquinaria(data_select){

        console.log(data_select)

        let me = this

        if(me.selected_list_items_producto_terminado[me.itemQFPIndex].po_maquinaria == ''){
          console.log("arreglo vacío de producto terminado en su campo de po_maquinaria en el índice: "+me.itemQFPIndex);

          const costo = data_select.costo_hora

          data_select = {...data_select,
            costo:costo,
            margen:0,
            pv:costo,
            minutos:0,
            descuento:"0.00",
          }

          me.selected_list_items_producto_terminado[me.itemQFPIndex].po_maquinaria[0].push(data_select)

          me.$bvModal.hide("modal-items"); 
          me.select_option = '';

        } else {
          console.log("arreglo no vacío de producto terminado en su campo de po_maquinaria en el índice:  "+me.itemQFPIndex);

          me.selected_list_items_producto_terminado[me.itemQFPIndex].po_maquinaria.filter(function(element) {
            console.log("arreglo lleno: ");
            for(let i=0; i<element.length; i++ ){
              if ( element[i].id == data_select.id ){
                Swal.fire({
                  title: "Que mal!",
                  text: 'Ya está agregado a la lista',
                  icon: "error",
                });
                me.$bvModal.hide("modal-items");
                me.select_option = '';
                return;
              } 
            }

            console.log("no deberias estar haciendo el push")

            const costo = data_select.costo_hora

            data_select = {...data_select,
              costo:costo,
              margen:0,
              pv:costo,
              minutos:0,
              descuento:"0.00",
            }

            me.selected_list_items_producto_terminado[me.itemQFPIndex].po_maquinaria[0].push(data_select);
            me.$bvModal.hide("modal-items");
            me.select_option = '';
          });
        }
      },

      SelectItemsOperadores(data_select){
        let me = this

        console.log(data_select)

        if(me.selected_list_items_producto_terminado[me.itemQFPIndex].po_operadores == ''){
          console.log("arreglo vacío de producto terminado en su campo de operadores en el índice: "+me.itemQFPIndex);

          const costo = data_select.costo_hora

          data_select = {...data_select,
            costo:costo,
            margen:0,
            pv:costo,
            minutos:0,
            descuento:"0.00",
          }

          me.selected_list_items_producto_terminado[me.itemQFPIndex].po_operadores[0].push(data_select)

          me.$bvModal.hide("modal-items"); 
          me.select_option = '';

        } else {
          console.log("arreglo no vacío de producto terminado en su campo de operadores en el índice:  "+me.itemQFPIndex);

          me.selected_list_items_producto_terminado[me.itemQFPIndex].po_operadores.filter(function(element) {

            for(let i=0; i<element.length; i++ ){
              if ( element[i].id == data_select.id ){
                Swal.fire({
                  title: "Que mal!",
                  text: 'Ya está agregado a la lista',
                  icon: "error",
                });
                me.$bvModal.hide("modal-items");
                me.select_option = '';
                return;
              } 
            }

            console.log("no deberias estar haciendo el push")

            const costo = data_select.costo_hora

            data_select = {...data_select,
              costo:costo,
              margen:0,
              pv:costo,
              minutos:0,
              descuento:"0.00",
            }

            me.selected_list_items_producto_terminado[me.itemQFPIndex].po_operadores[0].push(data_select)
            me.$bvModal.hide("modal-items");
            me.select_option = '';
          });
        }
      },

      SaveItemMateriaPrima(index_Q_P_T, index_Q_P_T_S_M_P, id_Q_P_T){

        const context = this

        console.log("hay que guardar nuevo en materia prima!!")

        console.log(id_Q_P_T)

        /**
        * Cuando vamos a agregar una nueva materia priam
        * tenemos que considerar que se tiene que agregar a nuestro array
        * de producto_termiando -> materia_prima.
        * Pero en sí lo que buscarmos hacer es insertar en la tabla
        * quote_finished_product_service_materia_prima
        * donde de entre todos los datos, lo importante son los siguientes:
        * q_f_p_id
        * m_p_id
        * s_id 
        */

        /**
        * vamos a por el momento solo a ver qué tenemos disponible en el objeto que podemos insertar... 
        */
        console.log(context.selected_list_items_producto_terminado[index_Q_P_T].po_materia_prima[0][index_Q_P_T_S_M_P])

        const m_p_id = context.selected_list_items_producto_terminado[index_Q_P_T].po_materia_prima[0][index_Q_P_T_S_M_P].id

        const s_id = context.selected_list_items_producto_terminado[index_Q_P_T].po_materia_prima[0][index_Q_P_T_S_M_P].service_id

        const q_f_p_id = id_Q_P_T

        context.selected_list_items_producto_terminado[index_Q_P_T].po_materia_prima[0][index_Q_P_T_S_M_P]["m_p_id"] = m_p_id
        context.selected_list_items_producto_terminado[index_Q_P_T].po_materia_prima[0][index_Q_P_T_S_M_P]["s_id"] = s_id
        context.selected_list_items_producto_terminado[index_Q_P_T].po_materia_prima[0][index_Q_P_T_S_M_P]["q_f_p_id"] = q_f_p_id


        console.log("mi id de materia prima: "+m_p_id)

        console.log("mi id de servicio de materia prima: "+s_id)

        console.log("mi id de producto terminado: "+q_f_p_id)

        console.log(context.selected_list_items_producto_terminado[index_Q_P_T].po_materia_prima[0][index_Q_P_T_S_M_P])

        const final_object = context.selected_list_items_producto_terminado[index_Q_P_T].po_materia_prima[0][index_Q_P_T_S_M_P]

        /**
        * Hasta este punto, ya tenemos nuestro objeto formado, debemos tomar en cuenta que es necesario el renombremienteo de los campos:
        * m_p_id
        * s_id
        * q_f_p_id
        * Por que esos campos son lo que espera recibir nuestro controlador, ahora si vamos a invocar a nuestro controlador haber que onda ...
        */

        context.selected_list_items_producto_terminado[index_Q_P_T].importe = 0

        // esta operación se encarga de actualizar los costos del producto terminado en cuestion
        // pero como en este caso no se manejan costos, pudiera no ser necesarios actualizar
        //context.SaveItemProductoTerminado(index_Q_P_T)

        let auth = JSON.parse(localStorage.autentication);

        MateriaPrima.CreateItemPOMateriaPrima(JSON.stringify(final_object), auth)
        .then((response) => {
          console.log("Respuesta de create materia prima: "+response.message)
          // cuando nosotros guardamos, lo que hace nuestro controlador es actualizar la información de la cotización
          // (y por ende la información que se despliega)
          // pero en nuestro caso, posiblemente tengamos que utilizar otro controlador pues la informaciónque 
          // tanto como se guarda como se obtiene, va a venir de otra base de datos
          // por lo que por el momento, será suficiente con saber que se guarda en base de datos.

          // context.GetQuoteInfo(context.quote_id)
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          context.GetQuoteInfo(context.quote_id)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      UpdateItemMateriaPrima(index_Q_P_T, index_Q_P_T_S_M_P, id_Q_P_T, id_Q_P_T_S_M_P){

        console.log("hay que actualizar materia prima!! " + id_Q_P_T_S_M_P)

        console.log("índice de cotizacion_producto_terminado: "+index_Q_P_T)

        console.log("índice de cotizacion_producto_terminado_service_materia_prima; "+index_Q_P_T_S_M_P)

        console.log("id de quote_producto_terminado: "+id_Q_P_T)

        console.log("id de quote_producto_terminado_service_materia_prima: "+id_Q_P_T_S_M_P)

        this.selected_list_items_producto_terminado[index_Q_P_T].importe = 0
        
        // this.SaveItemProductoTerminado(index_Q_P_T)



        /**
        * Hasta el momento, tenemos los elementos necesarios para proceder a la actualización
        * vamos a analizar nuestro endpoint para determinar los ajustes a realizar
        * pues como mínimo y de hecho más importante, hay que considerar que se puede enviar el id del producto terminado
        * y que más adelante tendremos el problema de ver qué pasa cuando no haya un id de producto terminado por agregar
        * lo anterior pasará cuando se agregue un nuevo producto terminado
        */

        let auth = JSON.parse(localStorage.autentication);

        MateriaPrima.UpdateItemPOMateriaPrima(JSON.stringify(this.selected_list_items_producto_terminado[index_Q_P_T].po_materia_prima[0][index_Q_P_T_S_M_P]),auth)
        .then((response) => {
          Swal.fire({
              title: "Genial!",
              text: response.data.message,
              icon: "success",
          });
          this.GetQuoteInfo(this.quote_id)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      DeletedSelectMateriaP(q_f_p_s_m_p_id, materiaPrimaArrayIndex, productoTerminadoArrayIndex){

        Swal.fire({
          title: "¿Esta seguro de eliminar de la lista?",
          text: "¿Eliminar?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: "Sí, eliminar",
          cancelButtonText: "Cancelar",
        })
        .then(resultado => {
          if (resultado.value) {
            const context = this

            if(q_f_p_s_m_p_id > 0){
              console.log("Hay que desde la base de datos")
              let auth = JSON.parse(localStorage.autentication)
              MateriaPrima.DeleteItemQuotFPMateriaPrima(q_f_p_s_m_p_id, auth)
              .then((response) => {
                Swal.fire({
                  title: "Genial!",
                  text: response.data.message,
                  icon: "success",
                });
                context.GetQuoteInfo(context.quote_id)
              })
              .catch((error) => {
                const obj = error.response.data.errors;
                Object.entries(obj).forEach( elements => 
                  Swal.fire({
                    title: "Que mal!",
                    text: elements[1][0],
                    icon: "error",
                  })
                );
              });
            } else if (q_f_p_s_m_p_id == 0){
              try{
                context.selected_list_items_producto_terminado[productoTerminadoArrayIndex].po_materia_prima[0].splice(materiaPrimaArrayIndex,1)
              } catch (e) {
                console.log(e)
              }
            } else {
              try{
                context.selected_list_items_producto_terminado[productoTerminadoArrayIndex].po_materia_prima[0].splice(materiaPrimaArrayIndex,1)
              } catch (e) {
                console.log(e)
              }
            }

          } else {
            // Dijeron que no
            console.log("*NO se elimina la lista*");
          }
        });  
      },

      SaveItemMaquinaria(index_Q_P_T, index_Q_P_T_M, id_Q_P_T){
        const context = this

        console.log("hay que guardar nuevo en maquinaria!!")

        console.log(id_Q_P_T)

        /**
        * Cuando vamos a agregar una nueva maquinaria
        * tenemos que considerar que se tiene que agregar a nuestro array
        * de producto_termiando -> maquinaria.
        * Pero en sí lo que buscarmos hacer es insertar en la tabla
        * quote_finished_product_machineries
        * donde de entre todos los datos, lo importante son los siguientes:
        * q_f_p_id
        * machinery_id
        */

        /**
        * vamos a por el momento solo a ver qué tenemos disponible en el objeto que podemos insertar... 
        */
        console.log(context.selected_list_items_producto_terminado[index_Q_P_T].po_maquinaria[0][index_Q_P_T_M])

        const machinery_id = context.selected_list_items_producto_terminado[index_Q_P_T].po_maquinaria[0][index_Q_P_T_M].id

        const q_f_p_id = id_Q_P_T

        context.selected_list_items_producto_terminado[index_Q_P_T].po_maquinaria[0][index_Q_P_T_M]["machinery_id"] = machinery_id
        context.selected_list_items_producto_terminado[index_Q_P_T].po_maquinaria[0][index_Q_P_T_M]["q_f_p_id"] = q_f_p_id
        context.selected_list_items_producto_terminado[index_Q_P_T].po_maquinaria[0][index_Q_P_T_M]["total"] = context.selected_list_items_producto_terminado[index_Q_P_T].po_maquinaria[0][index_Q_P_T_M].total_dinero
        context.selected_list_items_producto_terminado[index_Q_P_T].po_maquinaria[0][index_Q_P_T_M]["corte_por_pieza"] = context.selected_list_items_producto_terminado[index_Q_P_T].po_maquinaria[0][index_Q_P_T_M].total_x_pieza

        console.log("mi id de maquinaria: "+machinery_id)

        console.log("mi id de producto terminado: "+q_f_p_id)

        console.log(context.selected_list_items_producto_terminado[index_Q_P_T].po_maquinaria[0][index_Q_P_T_M])

        const final_object = context.selected_list_items_producto_terminado[index_Q_P_T].po_maquinaria[0][index_Q_P_T_M]

        /**
        * Hasta este punto, ya tenemos nuestro objeto formado, debemos tomar en cuenta que es necesario el renombremienteo de los campos:
        * machinery_id
        * q_f_p_id
        * Por que esos campos son lo que espera recibir nuestro controlador, ahora si vamos a invocar a nuestro controlador haber que onda ...
        */


        context.selected_list_items_producto_terminado[index_Q_P_T].importe = 0

        // context.SaveItemProductoTerminado(index_Q_P_T)


        let auth = JSON.parse(localStorage.autentication);

        Maquinaria.CreateItemPOMaquinaria(JSON.stringify(final_object), auth)
        .then((response) => {
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          context.GetQuoteInfo(context.quote_id)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      UpdateItemMaquinaria(index_Q_P_T, index_Q_P_T_M, id_Q_P_T, id_Q_P_T_M){

        console.log("hay que actualizar maquinaria!! "+id_Q_P_T_M)

        console.log("índice de cotizacion_producto_terminado: "+index_Q_P_T)

        console.log("índice de cotizacion_producto_terminado_maquinaria: "+index_Q_P_T_M)

        console.log("id de quote_producto_terminado: "+id_Q_P_T)

        console.log("id de quote_producto_terminado_maquinaria: "+id_Q_P_T_M)

        console.log("objeto en cuestion de producto terminado: "+this.selected_list_items_producto_terminado[index_Q_P_T])

        console.log("objeto en cuestion de quote->producto_terminado->maquinaria")

        console.log(this.selected_list_items_producto_terminado[index_Q_P_T].po_maquinaria[0][index_Q_P_T_M])

        // this.selected_list_items_producto_terminado[index_Q_P_T].importe = this.totalDeCostoUnitario(index_Q_P_T)
        
        // this.SaveItemProductoTerminado(index_Q_P_T)

        let auth = JSON.parse(localStorage.autentication);
        Maquinaria.UpdateItemPOMaquinaria(JSON.stringify(this.selected_list_items_producto_terminado[index_Q_P_T].po_maquinaria[0][index_Q_P_T_M]),auth)
        .then((response) => {
          console.log("respuesta de maquinaria updata: "+response.message)
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          this.GetQuoteInfo(this.quote_id)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      DeleteSelectedMaquinaria(q_f_p_m_id, maquinariaArrayIndex, productoTerminadoArrayIndex){
        console.log("id de la materia prima: "+q_f_p_m_id)
        console.log("índice del array de la maquinaria: "+maquinariaArrayIndex)
        console.log("índice del array de producto terminado: "+productoTerminadoArrayIndex)

        Swal.fire({
          title: "¿Esta seguro de eliminar de la lista?",
          text: "¿Eliminar?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: "Sí, eliminar",
          cancelButtonText: "Cancelar",
        })
        .then(resultado => {
          if (resultado.value) {
            const context = this

            if(q_f_p_m_id > 0){
              console.log("Hay que desde la base de datos")
              let auth = JSON.parse(localStorage.autentication)
              Maquinaria.DeleteItemPOMaquinaria(q_f_p_m_id, auth)
              .then((response) => {
                Swal.fire({
                  title: "Genial!",
                  text: response.data.message,
                  icon: "success",
                });
                context.GetQuoteInfo(context.quote_id)
              })
              .catch((error) => {
                const obj = error.response.data.errors;
                Object.entries(obj).forEach( elements => 
                  Swal.fire({
                    title: "Que mal!",
                    text: elements[1][0],
                    icon: "error",
                  })
                );
              });
            } else if (q_f_p_m_id == 0){
              try{
                context.selected_list_items_producto_terminado[productoTerminadoArrayIndex].po_maquinaria[0].splice(maquinariaArrayIndex,1)
              } catch (e) {
                console.log(e)
              }
            } else {
              try{
                context.selected_list_items_producto_terminado[productoTerminadoArrayIndex].po_maquinaria[0].splice(maquinariaArrayIndex,1)
              } catch (e) {
                console.log(e)
              }
            }

          } else {
            // Dijeron que no
            console.log("*NO se elimina la lista*");
          }
        });
      },

      SaveItemOperador(index_Q_P_T, index_Q_P_T_O, id_Q_P_T){
        const context = this

        console.log("hay que guardar nuevo en operador!!")

        /**
        * Cuando vamos a agregar un nuevo operador
        * tenemos que considerar que se tiene que agregar a nuestro array
        * de producto_termiando -> maquinaria.
        * Pero en sí lo que buscarmos hacer es insertar en la tabla
        * quote_finished_product_collaborators
        * donde de entre todos los datos, lo importante son los siguientes:
        * q_f_p_id
        * collaborator_id
        */

        /**
        * vamos a por el momento solo a ver qué tenemos disponible en el objeto que podemos insertar... 
        */
        console.log(context.selected_list_items_producto_terminado[index_Q_P_T].po_operadores[0][index_Q_P_T_O])

        const collaborator_id = context.selected_list_items_producto_terminado[index_Q_P_T].po_operadores[0][index_Q_P_T_O].id

        const q_f_p_id = id_Q_P_T

        context.selected_list_items_producto_terminado[index_Q_P_T].po_operadores[0][index_Q_P_T_O]["collaborator_id"] = collaborator_id
        context.selected_list_items_producto_terminado[index_Q_P_T].po_operadores[0][index_Q_P_T_O]["q_f_p_id"] = q_f_p_id
        context.selected_list_items_producto_terminado[index_Q_P_T].po_operadores[0][index_Q_P_T_O]["total"] = context.selected_list_items_producto_terminado[index_Q_P_T].po_operadores[0][index_Q_P_T_O].total_dinero
        context.selected_list_items_producto_terminado[index_Q_P_T].po_operadores[0][index_Q_P_T_O]["corte_por_pieza"] = context.selected_list_items_producto_terminado[index_Q_P_T].po_operadores[0][index_Q_P_T_O].total_x_pieza

        console.log("mi id de operador: "+collaborator_id)

        console.log("mi id de producto terminado: "+q_f_p_id)

        const final_object = context.selected_list_items_producto_terminado[index_Q_P_T].po_operadores[0][index_Q_P_T_O]

        console.log(final_object)

        /**
        * Hasta este punto, ya tenemos nuestro objeto formado, debemos tomar en cuenta que es necesario el renombremienteo de los campos:
        * machinery_id
        * q_f_p_id
        * Por que esos campos son lo que espera recibir nuestro controlador, ahora si vamos a invocar a nuestro controlador haber que onda ...
        */

        context.selected_list_items_producto_terminado[index_Q_P_T].importe = 0

        //context.SaveItemProductoTerminado(index_Q_P_T)

        let auth = JSON.parse(localStorage.autentication);

        Operador.CreateItemPOOperador(JSON.stringify(final_object), auth)
        .then((response) => {
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          context.GetQuoteInfo(context.quote_id)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      UpdateItemOperador(index_Q_P_T, index_Q_P_T_O, id_Q_P_T, id_Q_P_T_O){

        console.log("índice de cotizacion_producto_terminado: "+index_Q_P_T)

        console.log("índice de cotizacion_producto_terminado_maquinaria: "+index_Q_P_T_O)

        console.log("id de quote_producto_terminado: "+id_Q_P_T)

        console.log("id de quote_producto_terminado_maquinaria: "+id_Q_P_T_O)

        console.log("objeto en cuestion de producto terminado: "+this.selected_list_items_producto_terminado[index_Q_P_T])

        console.log("objeto en cuestion de quote->producto_terminado->maquinaria")

        console.log(this.selected_list_items_producto_terminado[index_Q_P_T].po_operadores[0][index_Q_P_T_O])

        this.selected_list_items_producto_terminado[index_Q_P_T].importe = 0
        
        //this.SaveItemProductoTerminado(index_Q_P_T)

        let auth = JSON.parse(localStorage.autentication);
        Operador.UpdateItemPOOperador(JSON.stringify(this.selected_list_items_producto_terminado[index_Q_P_T].po_operadores[0][index_Q_P_T_O]),auth)
        .then((response) => {
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          this.GetQuoteInfo(this.quote_id)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      DeleteSelectedOperador(q_f_p_o_id, operadoresArrayIndex, productoTerminadoArrayIndex){
        console.log("id del registro de producto termiado -> operador: "+q_f_p_o_id)
        console.log("índice del array de la operador: "+operadoresArrayIndex)
        console.log("índice del array de producto terminado: "+productoTerminadoArrayIndex)

        Swal.fire({
          title: "¿Esta seguro de eliminar de la lista?",
          text: "¿Eliminar?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: "Sí, eliminar",
          cancelButtonText: "Cancelar",
        })
        .then(resultado => {
          if (resultado.value) {
            const context = this

            if(q_f_p_o_id > 0){
              console.log("Hay que desde la base de datos")
              let auth = JSON.parse(localStorage.autentication)
              Operador.DeleteItemPOOperador(q_f_p_o_id, auth)
              .then((response) => {
                Swal.fire({
                  title: "Genial!",
                  text: response.data.message,
                  icon: "success",
                });
                context.GetQuoteInfo(context.quote_id)
              })
              .catch((error) => {
                const obj = error.response.data.errors;
                Object.entries(obj).forEach( elements => 
                  Swal.fire({
                    title: "Que mal!",
                    text: elements[1][0],
                    icon: "error",
                  })
                );
              });
            } else if (q_f_p_o_id == 0){
              try{
                context.selected_list_items_producto_terminado[productoTerminadoArrayIndex].po_operadores[0].splice(operadoresArrayIndex,1)
              } catch (e) {
                console.log(e)
              }
            } else {
              try{
                context.selected_list_items_producto_terminado[productoTerminadoArrayIndex].po_operadores[0].splice(operadoresArrayIndex,1)
              } catch (e) {
                console.log(e)
              }
            }

          } else {
            // Dijeron que no
            console.log("*NO se elimina la lista*");
          }
        });
      },

      SaveAndCreateRoadMap(){
        const context = this
        let auth = JSON.parse(localStorage.autentication);

        const data = {
          "quote_id":context.quote_id
        }

        HojaViajera.CreateHojaViajera(data, auth)
        .then((response) => {
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          //context.GetQuoteInfo(context.quote_id)
          context.$router.push({ path: '/hoja-viajera/edit/'+context.quote_id})
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      async UpdateProductionOrder(){
        const context = this
        let auth = JSON.parse(localStorage.autentication);

        const data = context.quoteInfo.production_order

        OrdenProduccion.UpdateOrdenServicio(data,auth)
        .then((response) => {
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
           context.GetQuoteInfo(context.quote_id)
          // context.$router.push({ path: '/hoja-viajera/edit/'+context.quote_id})
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      ViewRoadMap(){
        const context = this
        context.UpdateProductionOrder().
        then(()  => {
          context.$router.push({ path: '/hoja-viajera/edit/'+context.quote_id})
        })
      },
  
      Option(event, itemQFPIndex) {
        let option = event.target.value
        let auth = JSON.parse(localStorage.autentication);

        this.itemQFPIndex = itemQFPIndex

        this.searchTerm = ""

        switch (parseInt(option)) {

          case 2:
            MateriaP.GetAllMateriaPrima(auth)
            .then((response) => {
              this.materiap = response.data;
              this.$bvModal.show("modal-items");
              this.totalRows = this.materiap.length
            })
            .catch((error) => {
              console.log(error.data);
            });
            break;
          
          case 3:
            Maquinarias.GetAllMaquinas(auth)
            .then((response) => {
              this.maquinariasList = response.data;
              this.$bvModal.show("modal-items");
              this.totalRows = this.maquinariasList.length
            })
            .catch((error) => {
              console.log(error.data);
            });
            break;

          case 4:
            Empleados.GetAllEmpleados(auth)
            .then((response) => {
              this.operadoresList = response.data;
              this.$bvModal.show("modal-items");
              this.totalRows = this.operadoresList.length
            })
            .catch((error) => {
              console.log(error.data);
            });
            break;
        
          default:
            break;
        }
      }
    },   

    mounted() {
      let me = this
      me.quote_id = me.$route.params.id
      me.AllClientes()
      me.AllSucursales()
      me.AllAlmacenes()
      me.GetQuoteInfo(me.quote_id);
    }
  };



</script>


<style scoped>
    #button_accordion{
      background: #272e38;
      border-color: #272e38;
    }

    #titulo {
      text-align: center;
    }

    hr#linea {
      margin-top: 1rem;
      margin-bottom: 1rem;
      border: 0;
      border-top: 1px solid #030303;
    }

    hr{
      border-top: 1px solid #000000 !important;
    }

    .v-line{
      border-left: solid #e8dbdb;
      height: 57%;
      left: 63%;
      position: absolute;
    }

    .form-group{
      margin-bottom: 5px!important;
    }

</style>