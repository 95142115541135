import Api from "../../../Api"

const END_POINT_CREATE_ITEM_PO_OPERADOR = 'user/production-order/collaborator/create';
const END_POINT_UPDATE_ITEM_PO_OPERADOR = 'user/production-order/collaborator/update';
const END_POINT_DELETE_ITEM_PO_OPERADOR = 'user/production-order/collaborator/delete';

export default {

  CreateItemPOOperador(data, auth){
    return Api.post(END_POINT_CREATE_ITEM_PO_OPERADOR, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  UpdateItemPOOperador(data, auth){
    return Api.patch(END_POINT_UPDATE_ITEM_PO_OPERADOR, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  DeleteItemPOOperador(id, auth){
    const options = {
      headers: {'Authorization': 'Bearer ' + auth.access_token},
      params: { id: id }
    }
    return Api.delete(END_POINT_DELETE_ITEM_PO_OPERADOR,options);
  }
}